import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Google Analytics Beratung": "/de/google-analytics-beratung",
  "Event Tracking": "/de/event-tracking"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/event-tracking"
);

const GoogleAnalyticsEventTracking = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Google Analytics Event Tracking einrichten [${currentYear}]`}
        description="Beschreibung wie Event Tracking in Google Analytics eingerichtet wird, am Beispiel eines Button Klicks - alles mit Google Tag Manager"
        lang="de"
        image="google-analytics-event-tracking-report-hero"
        alternateLangs={alternateLangs}
        datePublished="2020-09-05T04:32:43.188Z"
        dateModified="2023-11-16T06:56:07Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report-hero.png"
          alt="google analytics event report chart linie"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Event Tracking in Google Analytics</H>
        <p>
          Nach der Installation von Google Analytics, ist das Aufsetzen von Event Tracking der wichtigste Schritt, um
          Einblicke in das Verhalten der Nutzer zu gelangen.{" "}
        </p>
        <p>
          Das Ziel ist es Interaktionen mit der Webseite zu tracken, um daran den Erfolg seiner Webseite zu messen.
        </p>
        <p>
          Downloads oder Klicks auf Videos und andere Elemente werden dann als Ereignisse (Events) in Google Analytics
          registriert und als KPIs zur Analyse in Reports und Dashboards genutzt.
        </p>
        <p>
          Man spricht in diesem Zusammenhang oft auch von <i>Conversion Tracking</i>.
        </p>
        <p>
          Events oder <em>Ereignisse</em> können nähmlich verschiedene Nutzeraktionen darstellen. Die resultierenden{" "}
          <i>Conversions</i> lassen sich dann in <i>harte</i> oder <i>weiche Konvertierungen</i> einteilen, abhängig
          von ihrer Wichtigkeit für die Ziele der Webseite.
        </p>
        <p>
          Schließlich erreicht man, dass die Performance von digitalen Kampagnen und Marketingaktivitäten auf
          Grundlage von Conversions und erreichten Zielen beurteilt werden kann. Google Analytics' Event Tracking ist
          dafür die Grundvorraussetzung.
        </p>
        <H as="h2">Was ist Event Tracking?</H>
        <p>
          Ein Ereignis (Event) ist entweder eine <strong>aktive Nutzeraktion</strong> oder ein{" "}
          <strong>passives Geschehen</strong>. Passiv ist zum Beispiel, wenn man in einer Produktkategorie für eine
          Mindestdauer verweilt, ohne aktiv zu interagieren.
        </p>
        <p>
          Es gibt verschiedene Möglichkeiten für die Anwendung von Event Tracking, da Ereignisse nach Belieben
          konfiguriert werden können.
        </p>
        <H as="h3">Beispiele</H>
        <ul>
          <li>Produkt zum Einkaufskorb hinzufügen</li>
          <li>Newsletter Anmeldung</li>
          <li>in einem Artikel bis zum Ende scrollen</li>
          <li>Video abspielen</li>
          <li>Login</li>
          <li>In ein Bild hineinzoomen</li>
          <li>Formular absenden</li>
          <li>Externe Link-Klicks</li>
          <li>Artikel drucken</li>
          <li>Datei download</li>
        </ul>
        <br />
        <p>
          Events sind meistens mit einer <b>Nutzeraktion</b> verbunden, d.h.
        </p>
        <ul>
          <li>Klicks mit der Maus</li>
          <li>Bewegungen mit der Maus</li>
          <li>Scrollen</li>
          <li>Navigation im Browser</li>
          <li>Eingaben mit der Tastatur</li>
        </ul>
        <p>
          Ereignisse können jedoch auch <strong>passiv</strong> sein, ohne jegliche Art von Aktion durch den Nutzer.{" "}
          <br />
          Beispiele sind z.B. Event Tracking für Server Fehler oder automatisierte Scripts, wie wenn z.B. ein Chatbot
          den Besucher anspricht.
        </p>
        <H as="h2">Event Typen</H>
        <p>Man kann zwischen zwei Arten von Events unterscheiden:</p>
        <p>
          <strong>Events mit aktiver Nutzeraktion</strong>, zum Beispiel:
        </p>
        <ul>
          <li>Klicks mit der Maus</li>
          <li>Bildschirmberührung</li>
          <li>Tastatureingaben</li>
        </ul>
        <p>
          <strong>Passive events ohne Nutzeraktion</strong>, zum Beispiel:
        </p>
        <ul>
          <li>Überschreiten einer Verweildauer</li>
          <li>Server Antworten</li>
          <li>Automatisierte Validierungen</li>
          <li>Scriptausführung von Drittanbietern</li>
        </ul>
        <p>
          Man kann also Interaktionen, sowie passive und automatisierte Geschehnisse durch Event Tracking einfangen.
          Das heißt ein großer Teil der Customer Journey kann rekonstruiert werden.
        </p>
        <p>
          Solche Einsichten lassen es wiederum zu, Schlüsse über die User Experience, die Inhalte und das Design der
          Website zu ziehen.
        </p>
        <H as="h2">Struktur von Google Analytics Events</H>
        <p>
          Google Analytics Events bringen einige standardisierte <em>Event-Paramter</em>, bzw.{" "}
          <em>Datenstrukturen</em> mit sich, um die Ereignisse zu kategorisieren.
        </p>
        <p>Ereignisse in Google Analytics werden durch die folgenden Dimensionen und eine Metrik beschrieben:</p>
        <ul>
          <li>
            <strong>Ereignis-Kategorie</strong> - (<em>event category</em>) - zur Kategorisierung in Eventgruppen. Bei
            einem Kontaktformular könnte man z.B. alle Events der Kategorie “Kontakt” zuordnen.
          </li>
          <li>
            <strong>Ereignis-Aktion</strong> - (<em>event action</em>) - zur Beschreibung der Interaktion, z.B.
            “Kontaktformular abschicken”.
          </li>
          <li>
            <strong>Ereignis-Label</strong> - (<em>event label</em>) - um zusätzliche Informationen hinzuzufügen. Nach
            der Kategorie und der Aktion pflegt das Label genauere Informationen zu beinhalten, z.B. Produktnamen, IDs
            oder Preise.
          </li>
          <li>
            <strong>Ereignis-Wert</strong> - (<em>event value</em>, <em>optional</em>) - um dem Event einen
            numerischen Wert zuzuordnen. Bei einem Kontaktformular, könnte man z.B. einen Wert von <strong>4</strong>{" "}
            zuordnen, denn wenn jede 25ste Kontaktaufnahme zu einer 100€ Transaktion führt, ist der durchscnittliche
            Wert 4€.
          </li>
          <li>
            <strong>Ereignis-(nicht-)Interaktion</strong> - (<em>event non-interaction</em>) - um zwischen{" "}
            <strong>aktiven</strong> und <strong>passiven</strong> Interaktionen zu unterscheiden. Der Standardwert
            steht auf <code>false</code>, d.h. es wird grundsätzlich von einem <em>aktiven Event</em> ausgegangen.
            Dies kommt für die Berechnung der <Link to="/de/wiki-analytics/bounce-rate-absprungrate">Bounce-Rate</Link> zum Tragen, da nur aktive Events auf die Berechnung Einfluss
            haben.
          </li>
        </ul>
        <H as="h2">Google Analytics Event Tracking Implementierung</H>
        <p>Es gibt grundsätzlich zwei Möglichkeiten Event Tracking einzurichten:</p>
        <ol>
          <li>
            Einrichten von <strong>Event Tracking mittels JavaScript Funktionen</strong>. Die JavaScript Snippets
            werden im Quelltext direkt auf den HTML-Elementen mittels onclick-Handler implementiert. Dieser Ansatz ist
            der ältere, einfachere Ansatz und kommt ggf. für statische Websites in Frage, da Seiten und Templates als
            Dateien auffindbar sind und nicht dynamisch generiert werden.
            <br />
            <br />
            <strong>Dieser Ansatz ist nicht zu empfehlen</strong>, da das Implementieren und Ändern fehleranfällig ist
            und nicht einfach skaliert werden kann. Außerdem muss ständig die Möglichkeit bestehen, den Quelltext zu
            ändern. Für die meisten Firmen sind solche Änderungen daher mit zu hohen Kosten verbunden. Wer diesen Weg
            jedoch bevorzugt, findet hier die <strong>Dokumentation</strong>:
            <ul>
              <li>
                <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/events">
                  analytics.js Event Tracking Dokumentation (alt)
                </a>
              </li>
              <li>
                <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/events">
                  gtag.js Event Tracking Dokumentation
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>Event Tracking einrichten mit Google Tag Manager</strong> oder einem anderen <Link to="/de/tag-management">Tag Management System</Link>.<br />
            Vorteile sind schnelle Anpassung, Skalierung der Konfiguration und Integration mit Drittanbietern.
            Außerdem können Anpassungen schnell ohne Änderungen im Quelltext vorgenommen werden. Daher stellt dies die
            bevorzugte Art der Implementierung dar.
            <br /><br />
            Wenn Du mehr wissen möchtest, lies meine Anleitung zum <Link to="/de/google-tag-manager-einrichten">Google Tag Manager Einrichten</Link>.
            <br /><br />
            Grundsätzlich besteht die Implementierung aus zwei Schritten: der Konfiguration eines Event-Triggers und
            eines Event-Tags. Die Werte für die Event-Paramter (Kategorie, Aktion, Label etc.) können im Event-Tag{" "}
            <strong>statisch</strong> oder <strong>dynamisch</strong> definiert werden - wobei letzteres bevorzugt
            ist.
          </li>
        </ol>
        <H as="h2">Event Tracking mit Google Tag Manager einrichten</H>
        <p>
          Als Beispiel möchte ich mit folgender Anleitung Event Tracking für den Klick auf einen Button einrichten,
          denn Klick-Tracking ist die üblichste Form des Event Trackings.
        </p>
        <H as="h3">Event Trigger Konfiguration</H>
        <ul>
          <li>
            Als erstes müssen wir in unseren <a href="https://tagmanager.google.com">Google Tag Manager account</a>{" "}
            einloggen und einen <strong>neuen Trigger erstellen</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-anlegen.png"
          alt="Ereignis Trigger in GTM anlegen"
        />
        <ul>
          <li>
            Gib dem Event Trigger einen <strong>Namen</strong>, zum Beispiel <strong>Klick - Button</strong> und klick
            danach in das Feld zur <strong>Trigger-Konfiguration</strong>. Danach taucht die Liste mit den möglichen
            Triggertypen auf.
          </li>
          <li>
            Wähl <strong>Alle Elemente</strong>. Danach soll der Trigger nur bei <strong>Einigen Klicks</strong>{" "}
            ausgelößt werden. Nach der Wahl, zeigen sich weitere Einstellungsmöglichkeiten.
          </li>
          <li>
            Wir müssen nun eine Regel erstellen, die beschreibt <em>wann</em> der Trigger ausgelöst werden soll. Dafür
            wählen wir im ersten Dropdown entweder direkt <strong>Click Element</strong>, oder - falls dies nicht im
            Dropdown zu finden ist - <strong>Integrierte Variable auswählen</strong>. Danach{" "}
            <strong>Click Element</strong> aus der erscheinenden Liste aktivieren.
          </li>
          <li>
            In der Definition für die Trigger-Regel, wählen wir im nächsten Feld{" "}
            <strong>Übereinstimmung mit CSS-Selektor</strong>.
          </li>
          <li>
            Jedes HTML Element kann durch einen CSS-Selektor gewählt werden. Ein CSS-Selektor ist also eine
            Selektionsregel, um programmatisch ein HTML Element zu bestimmen. In unserem Fall ist es ein Button
            Element. Wir könnten auch Klicks für andere HTML Elemente einrichten z.B. <strong>Bilder</strong> oder{" "}
            <strong>Links</strong>. Es ist die selbe Vorgehensweise.
          </li>
          <li>
            Wo bekommen wir den CSS-Selektor her? Ganz einfach: Im Chrome Browser auf das beliebige Element{" "}
            <strong>rechts-klicken</strong> und <strong>Untersuchen</strong> wählen. Danach öffnet sich Chromes
            Developer Tools und das geklickte Element wird im Quelltext hervorgehoben.
          </li>
          <li>
            Jetzt einfach rechts auf das Element klicken und <strong>Copy</strong> &gt; <strong>Copy JS path</strong>{" "}
            auswählen. (<strong>Copy selector</strong> wäre grundsätzlich die richtige Wahl, sie führt nur manchmal zu
            einem fehlerhaften Selektor)
          </li>
        </ul>
        <ImgContainerFixed width="529px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-css-selektor-kopieren.png"
            alt="CSS-Selektor kopieren"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Wenn wir den Inhalt des Clipboards ein einen Editor einfügen, sehen wir in etwa folgendes:
            <br />
            <code>document.querySelector(&quot;body &gt; ul &gt; button&quot;)</code>
          </li>
          <li>
            Wir sind nur an dem Teil in den Anführungszeichen interessiert, also: <br />{" "}
            <code>body &gt; ul &gt; button</code>
          </li>
          <li>
            <strong>Das ist der CSS-Selektor</strong> den wir für das Konfigurationsfeld in der Trigger-Regel in
            Google Tag Manager benötigen (<em>siehe unten</em>).
          </li>
          <li>
            Den <strong>CSS-Selektor</strong> einfügen und <strong>speichern</strong>
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-konfiguration.png"
          alt="Trigger Konfiguration für Button Klick in GTM"
        />
        <p>Nur als Hinweis: Genau den gleichen Vorgang kann man auch für andere Elemente durchführen.</p>
        <p>
          Wir sind noch nicht ganz fertig. Nach der Regel für den Klick-Trigger, muss das Tag eingestellt werden. Hier
          wird bestimmt welche Daten zu Google Analytics geschickt werden, wenn der Trigger ausgelöst wird.
        </p>
        <H as="h3">Event Tag in Google Tag Manager erstellen</H>
        <p>
          Nachdem der Klick-Trigger konfiguriert ist, fehlt nur noch <em>was</em> geschehen soll, wenn der Trigger
          ausgelöst wird. Mit Triggern wird also immer beschrieben <em>wann</em> etwas geschehen soll (
          <em>wenn der Button geklickt wird</em>) und danach <em>was</em> ausgeführt wird. In unserem Fall, soll sein{" "}
          <strong>Google Analytics Event Tag</strong> mit Werten für Ereigniskategorie, -aktion und -label ausgeführt
          werden.
        </p>
        <ul>
          <li>
            Um ein Google Analytics Event Tag zu erstellen, müssen wir im GTM Hauptmenü<em>Tags</em> besuchen und auf{" "}
            <em>neu</em> klicken.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-tag-erstellen.png"
          alt="Custom Event Tag in Google Tag Manager erstellen"
        />
        <ul>
          <li>
            Als Name <strong>Button Event</strong> angeben und danach auf die <strong>Tag-Konfiguration</strong>{" "}
            klicken.
          </li>
          <li>
            In der erscheinenden Liste aus Tagtypen wählen wir <strong>Google Analytics - Universal Analytics</strong>
            .
          </li>
          <li>
            Zurück in der Tag-Konfiguration wählen wir <strong>Ereignis</strong> als <strong>Tracking-Typ</strong>.
            Nun erscheinen weitere Eingabefelder für die <em>Event-Parameter</em>.
          </li>
          <li>
            Für <strong>Ereigniskategorie</strong> und <strong>-aktion</strong> können vorerst <em>statische</em>{" "}
            Werte verteilt werden. <strong>Click Tracking</strong> für die Kategokrie und <strong>Click</strong> für
            die Aktion, zum Beispiel.
            <br /> Für das <strong>Ereignis-Label</strong> wollen wir einen <em>dynamischen</em> Wert. Wir klicken
            daher auf den kleinen Legostein neben dem Eingabefeld.
          </li>
          <li>
            Falls in der Variablenliste <strong>Click Text</strong> vorhanden ist, bitte auswählen. Falls nicht: Oben
            rechts auf <strong>Integrierte</strong> klicken, um alle integrierten Variablen angezeigt zu bekommen.
            Dort dann <strong>Click Text</strong> auswählen.
          </li>
          <li>
            Nach der Auswahl landen wir wieder in der Tag-Konfiguration, wo nun <code>{"{{Click Text}}"}</code> in das
            Label-Felt eingefügt wurde. Die geschweiften Klammern werden für Variablen, anstatt statischer Werte,
            genutzt.
          </li>
          <li>
            Unter <strong>Wert</strong> kann dem Ereignis ein numärischer Wert zugeordnet werden. In unserem Beispiel
            kann ich dem Button Klick keinen Wert zuordnen und lasse das Feld daher leer.
          </li>
          <li>
            Im Dropdown <strong>Treffer ohne Interaktion</strong> wird zwischen aktiven und passiven Ereignissen
            unterschieden. Wenn das Ereignis aktiv vom Nutzer durchgeführt wird, gilt dies als aktiv und ist somit{" "}
            <em>nicht</em> ohne Interaktion. In unserem Beispiel klickt der Besucher den Button. Die Interaktion geht
            vom Besucher aus und kann damit als aktiv gewertet werden. Also <strong>Treffer ohne Interaktion</strong>{" "}
            auf <strong>Falsch</strong> setzen.
          </li>
          <li>
            In den <strong>Google Analytics Einstellungen</strong> kann eine vorher konfigurierte Einstellungsvariable
            gewählt werden. In meinem Beispiel überschreibe ich die Einstellungen und gebe die Tracking ID direkt an
            (z.B. <em>UA-12345678-91</em>).
          </li>
          <li>
            Zu guter Letzt muss noch der vorher erstellte Trigger als Auslöser eingestellt werden. Hier also den{" "}
            <strong>Click - Button</strong> Trigger wählen.
          </li>
        </ul>
        <p>Die gesamte Tag Konfiguration auf einen Blick:</p>
        <ImgContainerFixed width="444px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-tracking-konfiguration-ueberblick.png"
            alt="Überblick Event Tag Konfiguration in Google Tag Manager"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Zum Abschluss <strong>speichern</strong> klicken.
          </li>
        </ul>
        <p>Sehr gut! Als nächster Schritt muss nun getestet werden ob alles funktioniert.</p>
        <H as="h2">Debugging und Testen in Google Analytics</H>
        <p>
          Um in den Debugging-Mode zu wechseln, muss auf <strong>In Vorschau ansehen</strong> geklickt werden:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-vorschau-debugging-modus-aktivieren.png"
          alt="Debug-Mode in Google Tag Manager aktivieren"
        />
        <p>
          Bei einem Seitenaufruf auf der Webseite mit dem GTM Container, startet nun der Debugging-Modus automatisch auf Grund von <Link to="/de/wiki-analytics/cookies-de">Cookies</Link>, die GTM gesetzt hat.
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-debugging-mode.png"
          alt="Google Tag Manager Debugging"
        />
        <p>
          Um die Einstellungen zu testen, muss auf den Button geklickt werden. Im Debugging-Fenster werden in der
          linken Leiste alle Events in chronologischer Reihenfolge angezeigt. </p>

        <ImgContainerFixed width="555px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-button-click.png"
            alt="GTM Debugger Klick auf Button"
            className="article-img"
          />
        </ImgContainerFixed>

        <p> Nach dem Klick auf den Button sollte ein{" "}
          <em>Click</em> Event in der liste stehen. Der eingerichtete <strong>Button Event</strong> sollte
          gleichzeitig rechts in der Übersicht angezeigt werden.
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-debugger-button-click-event.png"
          alt="Google Tag Manager Debugger Button-Klick"
        />
        <p>
          Wenn man in der Übersicht auf den <strong>Button Event</strong> klickt, kann man die gesendeten
          Event-Parameter prüfen:
        </p>
        <ImgContainerFixed width="496px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-event-properties-for-category-action-label.png"
            alt="GTM Debugger Event-Properties"
          />
        </ImgContainerFixed>
        <p>
          Die <strong>Click Text</strong> Variable gibt den Text des geklickten HTML Elements im Ereignis-Label
          wieder. Mein <strong>Ereignis-Label</strong> hält daher den Wert “OK”, da das auf meinem Button geschrieben
          steht.
        </p>
        <p>Wenn man weiter herunter scrollt, kann man nochmal die Regel für den Trigger begutachten:</p>
        <ImgContainerFixed width="413px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-trigger-rules.png"
            alt="Google Tag Manager Trigger mit Regel"
          />
        </ImgContainerFixed>
        <p>
          <strong>TIPP:</strong> Um die Event-Parameter in Google Analytics zu überprüfen, kann man den{" "}
          <strong>Echtzeit Bericht für Ereignisse</strong> besuchen. <br />
          Falls schon einige Minuten seit dem Button Klick vergangen sind, schafft die{" "}
          <strong>Ansicht für Ereignisse in den letzten 30 Minuten</strong> Abhilfe.
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-echtzeit-report.png"
          alt="Google Analytics Echtzeit Report"
        />
        <H as="h3">CSS-Selektor Testen</H>
        <p>
          Für den Fall, dass das Ereignis beim Debugging nicht ausgelöst wurde, hängt es wahrscheinlich mit dem{" "}
          <strong>CSS-Selektor</strong> zusammen.
          <br />
          <strong>TIPP:</strong> Wenn wir die Seite mit dem Button besuchen und nach dem Seitenaufruf{" "}
          <strong>F12</strong> drücken, öffnet sich <strong>Chrome Developer Tools</strong> und der{" "}
          <strong>Element Tab</strong> is gewählt.
          <br />
          Mit <strong>STRG+F</strong> starten wir die Element-Suche und ein kleines Suchfeld zeigt sich unter dem
          Quelltext.
        </p>
        <p>
          Wenn wir jetzt den CSS-Selektor aus der Trigger Konfiguration testen und{" "}
          <strong>in das Suchfeld einfügen</strong>, sollte das Element im Quelltext hervorgehoben werden.
        </p>
        <ImgContainerFixed width="578px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-find-by-css-selector.png"
            alt="Google Developer Tools Element mit CSS-Selektor finden"
          />
        </ImgContainerFixed>
        <p>
          Falls nichts passiert, stimmt etwas mit dem CSS-Selektor nicht. Alternativ gibt es auch Browser Plugins, die
          den CSS-Selektor für die Elemente anzeigen.
        </p>
        <p>
          Wenn alles funktioniert, können wir zurück zu Google Tag Manager und auf{" "}
          <strong>Vorschaumodus beenden</strong> klicken. Danach noch auf den großen <strong>Senden</strong> Button
          oben rechts klicken, um alle Einstellungen im Container zu speichern.
        </p>
        <H as="h2">Vorteile und Nachteile von Event Tracking mit CSS-Selektoren</H>
        <p>
          Event Tracking basierend auf CSS-Selektoren ist nicht die sicherste Art der Implementierung. Die komplette
          Konfiguration kommt zum erliegen, wenn der CSS-Selektor nicht funktioniert. Der Selektor ist damit eine{" "}
          <em>kritische Abhängigkeit</em>.{" "}
        </p>
        <p>Aber warum sollte der CSS-Selektor aufhören zu funktionieren?</p>
        <p>
          Kurzum: Je größer die Website, umso größer die Menge an Entwicklern und Teams, die daran arbeiten. Wenn die
          Element-Struktur sich verändert oder ein Klassenname unbenannt wird, wird Track dass auf CSS-Selektoren
          basiert ggf. nicht mehr funktionieren. Diesen Riskikofaktor muss man für jede Webseite einzeln beurteilen.
        </p>
        <p>
          Die sicherste Methode ist es mittels eines <strong>Onclick-Handlers</strong> und JavaScript die
          Event-Paramter in einen{" "}
          <strong>
            <Link to="/de/data-layer">Data Layer</Link>
          </strong>{" "}
          zu schicken. Der Vorteil ist, dass jeder Programmierer den Onclick-Handler sehen und nicht einfach löschen
          würde.
        </p>
        <p>
          Bei fortgeschrittenen Tracking Systemen, die mit Datenbanken und anderen Datenquellen integriert sind, ist
          ein Data Layer aufgrund von Sicherheit und Verlässlichkeit Standard. Daher würde man{" "}
          <Link to="/de/data-layer#event-tracking-mit-dem-data-layer">Event Tracking mit einem Data Layer</Link>{" "}
          implementieren.
        </p>
        <p>
          Bei einfacheren Webseiten und niedrigeren Ansprüchen ist der Aufwand für die Implementierung jedoch nicht
          unbedingt gerechtfertigt. Tracking mit CSS-Selektoren ist in solchen Fällen oft die einzige Möglichkeit, da
          kein Budget für einen Data Layer vorliegt.
        </p>
        <p>
          Die <strong>Vorteile</strong> beim Event Tracking mit CSS-Selektoren, liegen in der Einfachheit und
          schnellen Implementierung. Man braucht nicht auf eine Implementierung zu warten und kann sofort beginnen
          wichtige Daten zu sammeln. Tracking für Ereignisse kann somit sehr schnell auf diese Art implementiert
          werden, bis ggf. irgendwann Ressourcen für einen Data Layer zur Verfügung stehen.
        </p>
        <p>
          Der <strong>Nachteil</strong> liegt in dem Risiko, dass der CSS-Selektor irgendwann nicht mehr funktionieren
          könnte. Merkt man es früh, kann er jedoch schnell angepasst werden.{" "}
        </p>
        <p>
          Aus meiner Sicht ist die professionellere Vorgehensweise jedoch einmal <strong>richtig</strong> zu
          implementieren und später nicht mehr darüber nachdenken zu müssen. <br />
          Wer auf seiner Website Event Tracking mittels CSS-Selektoren implementiert, hat natürlich Kontrolle über
          Änderungen und kann die Risiken abwägen.
        </p>
        <p>
          Private oder kleine Webseiten-Betreiber haben die Änderungen auf ihrer Webseite meistens im Blick und können
          somit dieser Methode folgen.
        </p>
        <p>
          Unternehmen mit mehr Budget und höheren Ansprüchen an Qualität sollten in jedem Fall einen Data Layer als
          Basis für's Event Tracking implementieren.
        </p>
        <H as="h2">Google Analytics Event Tracking Report</H>
        <p>
          Jetzt, wo Event Tracking eingerichtet ist, müssen wir nur noch wissen wo man die Ereignisse in Google
          Analytics überschauen kann. Das Einfachste sind die verfügbaren{" "}
          <strong>Event Reports in Google Analytics</strong>. Darüber hinaus kann man einen{" "}
          <strong>benutzerdefinierten Bericht</strong> erstellen oder ein <strong>Dashboard</strong>.
        </p>
        <p>
          Den Report über die wichtigsten Ereignisse in Google Analytics findet man unter <strong>Verhalten</strong>{" "}
          &gt; <strong>Ereignisse</strong> &gt; <strong>Wichtigste Ereignisse</strong>.
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report-de.png"
          alt="GA Report für Ereignisse"
        />
        <p>
          Als Primäre Dimension ist die <strong>Ereigniskategorie</strong> vorgewählt. <strong>Ereignisaktion</strong>{" "}
          und <strong>Ereignis-Label</strong> können über dem Report ausgewählt werden.
        </p>
        <p>
          Der <strong>Durschnittliche Wert</strong> in der rechten äußeren Spalte zeigt den Durchschnitt von allen{" "}
          <strong>Ereigniswerten</strong> (<em>event value</em>). Praktisch für Events, die im Zusammenhang mit
          numerischen Werten stehen, wie z.B. Preisrechner oder Preis- und Größenfilter. Der Durchschnittswert aller
          Events wäre hier sofort einsehbar.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default GoogleAnalyticsEventTracking;
